.btn-w-a::after {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    transition-timing-function: cubic-bezier(.33,0,.25,1);
    transition-duration: .35s;
    transition-property: all;
    background-color: #4ED1FF;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.btn-w-a.outline::after {
    background-color: #222;
}

.btn-w-a:hover::after {
    left: 0;
}

.btn-w-a:hover .btn-arrow-h {
    background: var(--genus-purple);
    color: #fff;
}

.btn-w-a:hover .btn-arrow-h.is--op {
    background: white;
    color: black;
}

.btn-w-a.outline:hover {
  color: white;
}

.btn-w-a.outline:hover .btn-arrow-h {
  background: #4ED1FF;
  color: #fff;
}

.btn-w-a:hover .gl-m-l-arrow {
  margin-left: 0;
}

.btn-w-a.inline-btn::after {
    background-color: transparent;
}



.btn-arrow-h {
    position: relative;
    transition-timing-function: cubic-bezier(.33,0,.25,1);
    transition-duration: .35s;
    transition-property: all;
    z-index: 10;
}

.btn-arrow-h svg {
    color: currentColor;
    fill: currentColor;
}

.gl-btn-wrap > a > div:not(.btn-arrow-h) {
    position: relative;
    z-index: 10;
}

.gl-m-l-arrow {
    transition-timing-function: cubic-bezier(.33,0,.25,1);
    transition-duration: .35s;
    transition-property: all;
}

[data-split="line"],
[data-card="text"],
[data-card="box"],
.h-swap {
  visibility: hidden;
  opacity: 0;
}
.inline {
  display: inline-block;
}

.gl-card-content:hover .btn-arrow-h svg {
  transform: translateX(5px);
}

.gl-video-modal {
  display: flex; /* Keep display property constant to allow transitions */
  opacity: 0;
  visibility: hidden;
  -webkit-backdrop-filter: blur(0px);
  backdrop-filter: blur(0px);
  transition: opacity 0.2s ease-out, visibility 0s linear 0.2s,
    -webkit-backdrop-filter 0.5s ease-out, backdrop-filter 0.5s ease-out; /* Adjust transition timing */
  pointer-events: none; /* Prevent interaction when not visible */
}

.gl-video-modal.-open {
  opacity: 1;
  visibility: visible;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: opacity 0.2s ease-out, -webkit-backdrop-filter 0.2s ease-out,
    backdrop-filter 0.5s ease-out; /* Apply transitions */
  pointer-events: all; /* Enable interaction when visible */
}

.gl-marque-track {
  white-space: nowrap;
  will-change: transform;
  animation: marquee 50s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.btn-arrow-h svg {
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.swiper-slide {
  width: auto;
  height: auto;
}

.faq-item .question {
  position: relative;
  transition: padding-left 0.8s cubic-bezier(0.65, 0.05, 0.36, 1);
  padding-right: 2vw;
  padding-left: 0;
}

.faq-item:hover .question {
  padding-left: 2vw;
}

.faq-item.active .question {
  padding-left: 2vw;
}

.faq-item .plus {
  width: 1.176vw;
  height: 1.176vw;
  position: absolute;
  right: 0;
  top: 0.176vw;
}

.faq-item .plus:before {
  content: "";
  position: absolute;
  background-color: #fff;
  transition: opacity 0.4s cubic-bezier(0.65, 0.05, 0.36, 1),
    background-color 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.faq-item .plus:before {
  left: 0;
  top: 50%;
  background-color: #fff;
  transform: translateY(-50%);
  width: 100%;
  height: 0.01rem;
}

.faq-item .plus:after {
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 0.01rem;
  height: 100%;
}

.faq-item .plus:after,
.faq-item .plus:before {
  content: "";
  position: absolute;
  background-color: #bbcdcb;
  transition: opacity 0.4s cubic-bezier(0.65, 0.05, 0.36, 1),
    background-color 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.is-cursor {
  pointer-events: none;
}

.faq-item.active .question-line {
  transform: scaleX(1);
  transition-delay: 0.1s;
}

.faq-item:hover .question-line {
  transform: scaleX(1);
  transition-delay: 0.1s;
}

.faq-item .question-line {
  transition: transform 0.6s cubic-bezier(0.65, 0.05, 0.36, 1),
    background 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  position: absolute;
  transform-origin: 0 50%;
  transition-delay: 0ms;
  transform: scaleX(0);
  left: 0;
  top: 10px;
  width: 1.2vw;
  height: 1px;
  background: currentColor;
}

.faq-item.active .answer {
  transform: scaleY(1);
}

.gl-tab-li svg {
  opacity: 0;
  transition: all 0.3s;
}

.gl-tab-li.w--current svg {
  opacity: 1;
}

.text-is-link a:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  height: 1px;
  left: 0;
  background: currentColor;
  display: block;
  transform-origin: 0 0;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.4, 0.22, 0.21, 1.04);
  transition: transform 1s cubic-bezier(0.4, 0.22, 0.21, 1.04);
  -o-transition: -o-transform 1s cubic-bezier(0.4, 0.22, 0.21, 1.04);
  -moz-transition: transform 1s cubic-bezier(0.4, 0.22, 0.21, 1.04),
    -moz-transform 1s cubic-bezier(0.4, 0.22, 0.21, 1.04);
  transition: transform 1s cubic-bezier(0.4, 0.22, 0.21, 1.04),
    -webkit-transform 0.45s cubic-bezier(0.4, 0.22, 0.21, 1.04),
    -moz-transform 1s cubic-bezier(0.4, 0.22, 0.21, 1.04),
    -o-transform 1s cubic-bezier(0.4, 0.22, 0.21, 1.04);
}

.text-is-link a:hover:before {
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
}

.text-isnt-link a {
  padding: 5px 2px;
}

.text-isnt-link a:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  height: 1px;
  left: 0;
  background: currentColor;
  display: block;
  transform-origin: 0 0;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.4, 0.22, 0.21, 1.04);
  transition: transform 1s cubic-bezier(0.4, 0.22, 0.21, 1.04);
  -o-transition: -o-transform 1s cubic-bezier(0.4, 0.22, 0.21, 1.04);
  -moz-transition: transform 1s cubic-bezier(0.4, 0.22, 0.21, 1.04),
    -moz-transform 1s cubic-bezier(0.4, 0.22, 0.21, 1.04);
  transition: transform 1s cubic-bezier(0.4, 0.22, 0.21, 1.04),
    -webkit-transform 0.45s cubic-bezier(0.4, 0.22, 0.21, 1.04),
    -moz-transform 1s cubic-bezier(0.4, 0.22, 0.21, 1.04),
    -o-transform 1s cubic-bezier(0.4, 0.22, 0.21, 1.04);
}

.text-isnt-link a:hover:before {
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
}

.vv:before {
  content: "\25AA";
  margin-right: 0.5em;
  color: currentColor;
  font-size: 2em;
  vertical-align: middle;
}

.gl-quote-tab.w--current::after {
  content: ""; /* This is required to generate the pseudo-element */
  display: inline-block; /* Makes the pseudo-element behave like an inline-level block container */
  width: 10px; /* Size of the circle */
  height: 10px; /* Size of the circle */
  background-color: var(--genus-purple); /* Color of the circle */
  border-radius: 50%; /* Makes the shape a circle */
  position: absolute; /* Positions the pseudo-element in relation to its parent */
  right: 0; /* Adjusts the position to the right of the element. Negative value moves it outside the element */
  top: 50%; /* Positions the circle at the vertical center of the element */
  transform: translateY(-50%); /* Centers the circle horizontally */
}

.i-in-nav::after {
  content: ""; /* This is required to generate the pseudo-element */
  display: inline-block; /* Makes the pseudo-element behave like an inline-level block container */
  width: 5px; /* Size of the circle */
  height: 5px; /* Size of the circle */
  background-color: var(--genus-green); /* Color of the circle */
  border-radius: 50%; /* Makes the shape a circle */
  position: absolute; /* Positions the pseudo-element in relation to its parent */
  right: -5px; /* Adjusts the position to the right of the element. Negative value moves it outside the element */
  top: 50%; /* Positions the circle at the vertical center of the element */
  transform: translateY(-50%); /* Centers the circle horizontally */
}

.gl-card-icon:hover .s-i {
  transform: translateY(5);
}

.gl-picture-clip {
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0, 0 100%, 0 100%, 0 0);
}

[data-mobile="nav"] {
  display: none;
}
/* tablet */
@media screen and (max-width: 991px) {
  :root {
    --font-h1-hero: 4.2rem;
    --font-h2: 3rem;
    --font-h2-subheader: 3.4rem;
    --font-h3: 2.2rem;
    --font-h4: 2rem;
    --font-h5: 1.5rem;
    --font-sub: 3.5rem;
    --font-para: 1rem;
    --font-para-large: 1.1rem;
    --gl-logo-width: 3rem;
    --gl-box-radius: 2rem;
  }

  [data-mobile="reverse"] {
    flex-direction: column-reverse;
  }
  [data-mobile="hide"] {
    display: none;
  }
  [data-mobile="nav"] {
    display: flex;
  }
}

/* landscape */
@media screen and (max-width: 767px) {
  :root {
    --font-h1-hero: 3.5rem;
    --font-h2: 2.25rem;
    --font-h2-subheader: 2.25rem;
    --font-h3: 2.05rem;
    --font-h4: 1.85rem;
    --font-h5: 1.3rem;
    --font-sub: 3.5rem;
    --font-para: 1rem;
    --font-para-large: 1.1rem;
    --gl-logo-width: 3rem;
    --gl-box-radius: 2rem;
  }
}
/* portrait */
@media screen and (max-width: 479px) {
  :root {
    --font-h1-hero: 3rem;
    --font-h2: 2.2rem;
    --font-h2-subheader: 2.25rem;
    --font-h3: 1.6rem;
    --font-h4: 1.35rem;
    --font-h5: 1.2rem;
    --font-sub: 3.5rem;
    --font-para: 1rem;
    --font-para-large: 1.1rem;
    --gl-logo-width: 3rem;
    --gl-box-radius: 1.9rem;
  }
  [data-mobile="cta"] {
    display: none;
  }
}

/* .swiper-slide {
  max-width: 450px;
  width: 25vw;
} */

.gl-card-logo.lgg img {
  object-fit: contain;
}

.target-div-1,
.target-div-2 {
  position: relative;
  width: 100%;
  height: 5.5em;
}

.line {
  position: relative;
}
.line-mask {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #f8f8f8;
  opacity: 0.65;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.gl-fleet-item.swiper-slide {
  width: 100%;
  opacity: 0.3;
  transition: opacity 0.5s ease-out;
}

.gl-fleet-item.swiper-slide.swiper-slide-active {
  opacity: 1;
}

[data-logo] {
  transition: color 0.3s ease-out;
}

[data-form-quote="remove-item"] {
  display: none;
}
